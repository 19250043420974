<template>
  <v-row no-gutters>
    <v-data-table
      v-if="coreComponentCount"
      :footer-props="itemsPerPageOptions"
      :headers="headers"
      :items-per-page="itemsPerPage"
      :items="coreComponents"
      multi-sort
      :page="page"
      :search="search"
      :sort-by="sort"
      :sort-desc="desc"
      class="flex"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="labels.search"
          single-line
          hide-details
          :disabled="loading"
        />
      </template>
      <template v-slot:item.version="{ value }">
        <v-chip dark color="primary">
          {{ value }}
        </v-chip>
      </template>
      <template v-slot:item.image="{ value }">
        <pre>{{ value }}</pre>
      </template>
      <template v-slot:item.replicas="{ item }">
        {{ item.status.readyReplicas || 0 }} / {{ item.status.replicas }}
      </template>
      <template v-slot:item.last_condition="{ item, value }">
        <v-chip
          dark
          :color="item.last_status === 'True' ? 'primary' : 'error'"
        >
          {{ value }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          text outlined
          color="primary"
          :to="{
            name: 'core-logs',
            params: {
              envName: environment.name,
              name: item.metadata.name,
              version: item.version,
            },
          }"
        >
          {{ labels.logs }}
        </v-btn>
        <v-btn
          text outlined
          color="primary"
          class="pl-1"
          @click="showEnvironmentVariables(item)"
        >
          {{ labels.details }}
        </v-btn>
      </template>
    </v-data-table>
    <v-skeleton-loader loading v-else type="table" class="flex" />

    <details-dialog
      @close="showDetailsDialog = false"
      v-if="anyWorkloadDetail"
      :dialog-items="workloadDetails"
      :name="name"
      :show="showDetailsDialog"
      :version="version"
    />

  </v-row>
</template>

<script>
/* eslint prefer-template: "off" */
import { isEmpty } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { labels } from '@/assets/texts.json';

export default {
  name: 'coreComponentTable',
  components: {
    detailsDialog: () => import('@/components/detailsDialog.vue'),
  },
  beforeDestroy() {
    this.clearInterval();
  },
  created() {
    // this.clear('coreComponentCount');
    this.getData();
    this.setTimerMethod(this.getData);
    this.setInterval();
  },
  computed: {
    ...mapState('fetch', [
      'environment',
      'coreComponentCount',
      'coreComponents',
      'permissions',
      'workloadDetails',
    ]),
    ...mapState('shared', [
      'loading',
      'refresh',
    ]),
    anyWorkloadDetail() {
      return !isEmpty(this.workloadDetails);
    },
  },
  data: () => ({
    desc: [true, false],
    headers: [
      {
        text: 'Service',
        value: 'metadata.name',
      },
      {
        text: 'Version',
        value: 'version',
      },
      {
        text: 'Repository',
        value: 'repo',
      },
      {
        text: 'Started',
        value: 'started',
      },
      {
        text: 'Status',
        value: 'last_condition',
      },
      {
        text: 'Replicas',
        value: 'replicas', // slot (status.ready_replicas / status.replicas)
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ],
    itemsPerPage: 20,
    itemsPerPageOptions: { 'items-per-page-options': [20, 50, -1] },
    labels,
    name: null,
    page: 1,
    search: null,
    sort: ['last_condition', 'metadata.name'],
    showDetailsDialog: false,
    version: null,
  }),
  methods: {
    ...mapActions({
      clear: 'fetch/clear',
      clearInterval: 'fetch/clearInterval',
      fetchCoreComponents: 'fetch/fetchCoreComponents',
      fetchWorkloadDetails: 'fetch/fetchWorkloadDetails',
      setInterval: 'fetch/setInterval',
      setTimerMethod: 'fetch/setTimerMethod',
    }),
    getData() {
      const params = {
        environmentID: this.environment.id,
        namespace: this.environment.core_namespace,
      };
      this.fetchCoreComponents(params);
    },
    showEnvironmentVariables(item) {
      this.name = item.metadata.name;
      this.version = item.version;
      const params = {
        name: this.name,
        namespace: this.environment.core_namespace,
      };
      this.fetchWorkloadDetails({
        environmentID: this.environment.id,
        params,
      }).then(() => { this.showDetailsDialog = true; });
    },
  },
};
</script>

<style scoped>
  .text-truncate {
    max-width: 5vw;
  }
  .flat-btn {
    text-transform: none;
  }
</style>
