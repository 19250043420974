var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[(_vm.coreComponentCount)?_c('v-data-table',{staticClass:"flex",attrs:{"footer-props":_vm.itemsPerPageOptions,"headers":_vm.headers,"items-per-page":_vm.itemsPerPage,"items":_vm.coreComponents,"multi-sort":"","page":_vm.page,"search":_vm.search,"sort-by":_vm.sort,"sort-desc":_vm.desc},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.labels.search,"single-line":"","hide-details":"","disabled":_vm.loading},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.version",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"dark":"","color":"primary"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.image",fn:function(ref){
var value = ref.value;
return [_c('pre',[_vm._v(_vm._s(value))])]}},{key:"item.replicas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status.readyReplicas || 0)+" / "+_vm._s(item.status.replicas)+" ")]}},{key:"item.last_condition",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-chip',{attrs:{"dark":"","color":item.last_status === 'True' ? 'primary' : 'error'}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","outlined":"","color":"primary","to":{
          name: 'core-logs',
          params: {
            envName: _vm.environment.name,
            name: item.metadata.name,
            version: item.version,
          },
        }}},[_vm._v(" "+_vm._s(_vm.labels.logs)+" ")]),_c('v-btn',{staticClass:"pl-1",attrs:{"text":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.showEnvironmentVariables(item)}}},[_vm._v(" "+_vm._s(_vm.labels.details)+" ")])]}}],null,false,766953719)}):_c('v-skeleton-loader',{staticClass:"flex",attrs:{"loading":"","type":"table"}}),(_vm.anyWorkloadDetail)?_c('details-dialog',{attrs:{"dialog-items":_vm.workloadDetails,"name":_vm.name,"show":_vm.showDetailsDialog,"version":_vm.version},on:{"close":function($event){_vm.showDetailsDialog = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }